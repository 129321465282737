/**
 * アプリケーションの定数定義
 * 
 * 機能:
 * - APIゲートウェイのベースドメイン
 * - デフォルトサムネイル画像のパス
 * - 1ページあたりの記事表示数
 * - 静的ファイルのオリジン
 * - Google Analyticsの測定ID
 * 
 * 使用方法:
 * ```typescript
 * import { API_GATE_WAY_BASE_DOMAIN } from './constants';
 * ```
 */

export const API_GATE_WAY_BASE_DOMAIN =
  "thezthee32.execute-api.ap-northeast-1.amazonaws.com"; // @TODO: 後で修正
export const DEFAULT_THUMBNAIL_IMAGE = "/article.png";
export const ARTICLE_CONTENTS_PER_PAGE = 10;
export const STATIC_FILE_ORIGIN = "https://dauyr74brhphl.cloudfront.net";
export const REACT_APP_GA_MEASUREMENT_ID = "G-R1J6VJZ6Y5";
