/**
 * アプリケーションのエントリーポイント
 * 
 * 機能:
 * - Reactアプリケーションのルート要素の作成とレンダリング
 * - パフォーマンス計測機能の初期化
 * 
 * コンポーネント構成:
 * - App: アプリケーションのルートコンポーネント
 * - StrictMode: 開発時の潜在的な問題の検出
 * 
 * 使用方法:
 * Create React Appによって自動的に実行される
 * 
 * パフォーマンス計測:
 * reportWebVitalsを使用して以下の指標を計測:
 * - FCP (First Contentful Paint)
 * - LCP (Largest Contentful Paint) 
 * - CLS (Cumulative Layout Shift)
 * - FID (First Input Delay)
 * - TTFB (Time to First Byte)
 */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
