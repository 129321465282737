import { API_GATE_WAY_BASE_DOMAIN } from "./constants";

/**
 * APIクライアントクラス
 * 
 * 機能:
 * - HTTPリクエストの送信と管理を行う
 * - GET/POST/PATCHメソッドをサポート
 * - 自動リトライ機能を実装
 * - エラーハンドリングを提供
 * 
 * 使用方法:
 * ```typescript
 * // GETリクエストの例
 * const data = await ApiClient.get('users', { id: '123' });
 * 
 * // POSTリクエストの例
 * const result = await ApiClient.post('users', { name: 'John' });
 * ```
 * 
 * @class
 */
export default class ApiClient {
  private static protocol: string = "https";
  private static headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  private static maxRetries: number = 3; // 最大リトライ回数

  /**
   * GETリクエストを送信
   * 
   * 機能:
   * - 指定されたエンドポイントにGETリクエストを送信
   * - クエリパラメータの自動構築
   * - エラー時の自動リトライ
   * 
   * @param endpoint - リクエスト先のエンドポイント
   * @param params - クエリパラメータのオブジェクト（任意）
   * @param retries - 現在のリトライ回数（内部使用）
   * @returns レスポンスのJSONデータ
   * @throws エラー発生時やリトライ上限到達時
   */
  static async get(
    endpoint: string,
    params?: Record<string, string> | null,
    retries: number = 0,
  ): Promise<any> {
    let url = `${ApiClient.protocol}://${API_GATE_WAY_BASE_DOMAIN}/${endpoint}`;
    if (params) {
      const queryString = new URLSearchParams(params).toString();
      url += `?${queryString}`;
    }

    try {
      const response: Response = await fetch(url, {
        method: "GET",
        headers: ApiClient.headers,
      });
      return this.handleResponse(response);
    } catch (error) {
      if (retries < this.maxRetries) {
        console.warn(`リトライ中 (${retries + 1}/${this.maxRetries})...`);
        return this.get(endpoint, params, retries + 1); // 再試行
      } else {
        throw new Error(`最大リトライ回数に達しました: ${error}`);
      }
    }
  }

  /**
   * POSTリクエストを送信
   * 
   * 機能:
   * - 指定されたエンドポイントにPOSTリクエストを送信
   * - リクエストボディの自動JSON変換
   * - エラー時の自動リトライ
   * 
   * @param endpoint - リクエスト先のエンドポイント
   * @param body - リクエストボディ（JSON形式）
   * @param retries - 現在のリトライ回数（内部使用）
   * @returns レスポンスのJSONデータ
   * @throws エラー発生時やリトライ上限到達時
   */
  static async post(
    endpoint: string,
    body: Record<string, any>,
    retries: number = 0,
  ): Promise<any> {
    let url = `${ApiClient.protocol}://${API_GATE_WAY_BASE_DOMAIN}/${endpoint}`;

    try {
      const response: Response = await fetch(url, {
        method: "POST",
        headers: ApiClient.headers,
        body: JSON.stringify(body),
      });
      return this.handleResponse(response);
    } catch (error) {
      if (retries < this.maxRetries) {
        console.warn(`リトライ中 (${retries + 1}/${this.maxRetries})...`);
        return this.post(endpoint, body, retries + 1); // 再試行
      } else {
        throw new Error(`最大リトライ回数に達しました: ${error}`);
      }
    }
  }

  /**
   * PATCHリクエストを送信
   * 
   * 機能:
   * - 指定されたエンドポイントにPATCHリクエストを送信
   * - リソースの部分的な更新を実行
   * - エラー時の自動リトライ
   * 
   * @param endpoint - リクエスト先のエンドポイント
   * @param body - 更新するデータ（JSON形式）
   * @param retries - 現在のリトライ回数（内部使用）
   * @returns レスポンスのJSONデータ
   * @throws エラー発生時やリトライ上限到達時
   */
  static async patch(
    endpoint: string,
    body: Record<string, any>,
    retries: number = 0,
  ): Promise<any> {
    let url = `${ApiClient.protocol}://${API_GATE_WAY_BASE_DOMAIN}/${endpoint}`;

    try {
      const response: Response = await fetch(url, {
        method: "PATCH",
        headers: ApiClient.headers,
        body: JSON.stringify(body),
      });
      return this.handleResponse(response);
    } catch (error) {
      if (retries < this.maxRetries) {
        console.warn(`リトライ中 (${retries + 1}/${this.maxRetries})...`);
        return this.patch(endpoint, body, retries + 1); // 再試行
      } else {
        throw new Error(`最大リトライ回数に達しました: ${error}`);
      }
    }
  }

  /**
   * レスポンスのエラーハンドリング
   * 
   * 機能:
   * - レスポンスステータスの確認
   * - エラー時の適切な例外スロー
   * - 正常時のJSONデータ変換
   * 
   * @param response - フェッチリクエストのレスポンスオブジェクト
   * @returns レスポンスのJSONデータ
   * @throws レスポンスが正常でない場合の例外
   */
  private static async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      const errorText: string = await response.text();
      throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
    }
    return await response.json();
  }
}
