/**
 * 記事詳細ページコンポーネント
 * 
 * 機能:
 * - 記事の詳細情報を表示
 * - 記事のタイトル、本文、作成日時を表示
 * - コメント投稿フォームの表示
 * - コメント一覧の表示
 * - ローディング中とエラー時の表示切り替え
 * 
 * @returns {JSX.Element} 記事詳細ページコンポーネント
 */
import { Box } from "@mui/material";
import ArticleTitle from "../components/ArticleTitle";
// import ArticleContent from '../components/ArticleContent';
import CommentForm from "../components/CommentForm";
import CommentList from "../components/CommentList";
import styles from "../style.module.css";
import Footer from "../components/Footer";
import ApiClient from "../utils/apiClient";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tag, Comment } from "../types";
import ArticleContent from "../components/ArticleContent";

type Content = {
  id: string;
  title: string;
  content: string;
  eyecatch: Record<string, any>;
  createdAt: string;
  revisedAt: string;
  publishedAt: string;
  tag: Tag[];
  comment: Comment[];
};

const ArticleDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [content, setContent] = useState<Content | null>();
  const { id } = useParams();

  /**
   * 記事をAPIから取得する非同期関数
   * /api/blogs/:id エンドポイントにGETリクエストを送り、記事一覧を取得して状態にセットする
   * @returns Promise<void>
   */
  async function fetchArticle(): Promise<void> {
    try {
      const res: Content = await ApiClient.get(`blogs/${id}`);
      setContent(res);
    } catch (err) {
      setIsError(true);
      console.error("記事取得失敗:", err);
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * 画面表示時に記事データを取得
   */
  useEffect(() => {
    if (!id) {
      setIsLoading(false); // ローディングを解除
      setIsError(true); // エラー状態をセット
      console.error("記事IDが不明です");
      return;
    }
    fetchArticle();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError || content == null ? (
        <p>No article available.</p>
      ) : (
        <>
          <Box sx={{ mt: 4 }}>
            <ArticleTitle
              title={content.title}
              date={content.createdAt.slice(0, 10)}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <ArticleContent content={content.content} />
          </Box>
          <Box sx={{ mt: 6 }} className={styles.sectionLine} />
          <Box sx={{ mt: 6 }}>
            {content && (
              <Box sx={{ mt: 6 }}>
                <CommentForm content={content} onCommentPosted={fetchArticle} />
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 4 }}>
            {/* @TODO: ArticleConentにコメント投稿機能を追加 */}
            <CommentList comments={content.comment} />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
};

export default ArticleDetail;
