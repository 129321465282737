/**
 * 記事のコンテンツを表示するコンポーネント
 * 
 * 機能:
 * - HTMLコンテンツをReactコンポーネントとしてレンダリング
 * - 画像のモーダル表示
 * - コードブロックのシンタックスハイライト
 * - h1タグをh2に変換
 * 
 * @param {string} content - HTMLコンテンツ文字列
 * @returns {JSX.Element} レンダリングされたコンテンツ
 */
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import parse from "html-react-parser";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { base16AteliersulphurpoolLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

interface ArticleContentProps {
  content: string;
}

const ArticleContent: React.FC<ArticleContentProps> = ({ content }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string | null>(null);

  // 数式のパターンを検出する正規表現
  const blockMathRegex = /\$\$([\s\S]*?)\$\$/g;
  const inlineMathRegex = /\$((?!\$).*?)\$/g;

  // コンテンツ内の数式を変換
  const processedContent = content
    .replace(blockMathRegex, (match, formula) => {
      return `<div class="math-block">${formula.trim()}</div>`;
    })
    .replace(inlineMathRegex, (match, formula) => {
      return `<span class="math-inline">${formula.trim()}</span>`;
    });

  /**
   * 画像クリック時にモーダルウィンドウで表示
   */
  const handleImageClick = (src: string) => {
    setModalImageSrc(src);
    setOpen(true);
  };

  /**
   * 画像非アクティブ/閉じるボタン押下でモーダルウィンドウを閉じる
   */
  const handleClose = () => {
    setOpen(false);
    setModalImageSrc(null);
  };

  // HTML文字列をReact要素としてパースし、imgタグにonClickハンドラを追加
  const parsedContent = parse(processedContent, {
    replace: (domNode: any) => {
      if (domNode.name === "img" && domNode.attribs) {
        return (
          <Box
            component="img"
            src={domNode.attribs.src}
            alt={domNode.attribs.alt || "Image"}
            sx={{
              display: "block",
              margin: "0 auto",
              maxWidth: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => handleImageClick(domNode.attribs.src)}
          />
        );
      }

      if (
        domNode.name === "pre" &&
        domNode.children &&
        domNode.children[0].name === "code"
      ) {
        const codeNode = domNode.children[0];
        const languageClass = codeNode.attribs?.class || "";
        const language = languageClass.replace("language-", "") || "text";
        const codeContent = codeNode.children[0].data;

        return (
          <Box my={2}>
            <SyntaxHighlighter
              language={language}
              style={base16AteliersulphurpoolLight}
            >
              {codeContent}
            </SyntaxHighlighter>
          </Box>
        );
      }

      if (domNode.name === "h1") {
        return (
          // ブログタイトル以外にh1は含ませたくないのでh2に置き換える
          <Typography variant="h2" component="h2">
            {domNode.children[0].data}
          </Typography>
        );
      }

      // LaTeX数式のブロック表示（$$...$$）
      if (domNode.name === 'div' && domNode.attribs?.class === 'math-block') {
        try {
          return (
            <BlockMath math={domNode.children[0].data} />
          );
        } catch (error) {
          console.error('数式のレンダリングエラー:', error);
          return <div className="math-error">数式の表示エラー</div>;
        }
      }

      // LaTeX数式のインライン表示（$...$）
      if (domNode.name === 'span' && domNode.attribs?.class === 'math-inline') {
        try {
          return (
            <InlineMath math={domNode.children[0].data} />
          );
        } catch (error) {
          console.error('数式のレンダリングエラー:', error);
          return <span className="math-error">数式の表示エラー</span>;
        }
      }
    },
  });

  return (
    <>
      <Box sx={{ marginTop: 2 }}>{parsedContent}</Box>

      {/* モーダル */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent sx={{ position: "relative", p: 0 }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "white",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          {modalImageSrc && (
            <Box
              component="img"
              src={modalImageSrc}
              alt="Enlarged"
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ArticleContent;
