/**
 * ブログページコンポーネント
 * 
 * 機能:
 * - タグ一覧の表示と選択
 * - 記事一覧のグリッド表示
 * - タグによる記事のフィルタリング
 * - ローディング中とエラー時の表示切り替え
 * 
 * @returns {JSX.Element} ブログページコンポーネント
 */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ContentGrid from "../components/ContentGrid";
import Footer from "../components/Footer";
import TagList from "../components/TagList";
import ApiClient from "../utils/apiClient";
import styles from "../style.module.css";
import { ApiResponse, Tag } from "../types";

type Content = {
  id: string;
  name: string;
};

const BlogPage: React.FC = () => {
  // タグ一覧の状態
  const [tags, setTags] = useState<Tag[]>([]);
  // ローディング状態のフラグ
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // エラーメッセージの状態
  const [isError, setIsError] = useState<boolean>(false);
  // 選択されたタグの状態
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  /**
   * タグをAPIから取得する非同期関数
   * /api/tagsエンドポイントにGETリクエストを送り、タグ一覧を取得して状態にセットする
   * エラーが発生した場合はエラーメッセージをセットする
   * @returns Promise<void>
   */
  async function fetchTags(): Promise<void> {
    try {
      const res: ApiResponse<Content> = await ApiClient.get("tags");
      const tags: Tag[] = res.contents.map((data: Content) => ({
        id: data.id,
        name: data.name,
      }));
      // 「すべて」タグを先頭に追加
      setTags([{ id: "all", name: "すべて" }, ...tags]);
    } catch (err) {
      setIsError(true);
      console.error("タグ取得失敗:", err);
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * コンポーネントのマウント時にfetchTagsを実行
   * 初回レンダリング時のみAPIからタグを取得する
   */
  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : isError ? (
          <Box className={styles.sectionLine} />
        ) : (
          <TagList
            tags={tags}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
          />
        )}
      </Box>
      <Box sx={{ mt: 4 }}>
        <ContentGrid
          selectedTag={selectedTag?.id === "all" ? null : selectedTag}
        />
      </Box>
      <Box sx={{ mt: 8 }}>
        <Footer />
      </Box>
    </>
  );
};

export default BlogPage;
