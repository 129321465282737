/**
 * ページトラッキングのためのカスタムフック
 * 
 * 機能:
 * - Google Analytics 4の初期化と設定
 * - ページビューイベントの自動トラッキング
 * - URLの変更検知と追跡
 * 
 * 使用方法:
 * ```typescript
 * // Appコンポーネントなどで使用
 * const App = () => {
 *   usePageTracking();
 *   return <div>...</div>;
 * };
 * ```
 * 
 * @returns void
 */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { REACT_APP_GA_MEASUREMENT_ID } from "./constants";

const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    const gaMeasurementId = REACT_APP_GA_MEASUREMENT_ID;

    if (!gaMeasurementId) {
      console.error("Google Analytics Measurement ID is not defined.");
      return;
    }

    // Google Analyticsの初期化
    ReactGA.initialize(gaMeasurementId);

    ReactGA.send({
      hitType: "pageview", 
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;
